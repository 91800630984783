.work-card-content {
  background-color: var(--dark-green);
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.2);
  width: 50%;
  position: relative;
  bottom: 5rem;
  left: 4rem;
  height: 150px;
}

.work-card {
  margin-top: 2rem;
  padding-left: 2rem;
  height: 500px;
}

.work-card img {
  position: relative;
  width: 75%;
  overflow: hidden;
}

.work-card-image {
  width: 100%;
  height: 350px;
  overflow: hidden;
}

#clicked {
  margin-right: 3rem;
  height: 800px;
}

#clicked .work-card-image {
  transition: all 300ms;
  height: 200px;
  overflow: hidden;
}

#clicked .work-card-image img {
  transition: all ease-in 300ms;
  width: 100%;
}

#clicked .work-card-content {
  position: static;
  margin-bottom: 3rem;
}

@media screen and (max-width: 768px) {
  .work-card {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }
  .work-card-content {
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .work-card img {
    width: 100%;
  }

  #clicked {
    margin-right: 0;
    height: 100%;
  }
  #clicked .work-card-image {
    height: 200px;
  }
  #clicked .work-card-image img {
    width: 100%;
  }
}
