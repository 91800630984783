@import url(https://fonts.cdnfonts.com/css/fot-rodin-pro);

:root {
  --dark-grey: #3d3d3b;
  --dark-green: #486e42;
  --light-green: #85ab88;
  --light-pink: #f0d2d1;
  --lighter: #fcf1ed;
  --font-header-secondary: #979797;
}

* {
  font-family: "FOT-Rodin Pro", monospace;
}

ul {
  list-style-type: none;
}

body {
  background-color: var(--dark-grey);
  color: var(--lighter);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.work {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.work h1 {
  font-size: 300%;
  text-align: center;
  color: var(--light-green);
}

.no-margin-top {
  margin-top: 0;
}
