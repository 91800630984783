.extra-card-content {
  margin: 0;
  padding: 0;
  height: auto;
  background: var(--light-green);
}

.top-row {
  margin-left: 30%;
  margin-right: 30%;
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: space-between;
}

.extra-content-row {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: space-between;
}

.extra-content-row h2,
.top-row h2 {
  font-size: 1.5rem;
  color: var(--dark-green);
}

.extra-content-text {
  text-align: left;
}

.extra-content-text h2 {
  color: var(--dark-green);
}

#icon-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 20%;
}

#link-icons {
  width: 32px;
  height: 32px;
}

#technologies-list {
  color: var(--dark-grey);
  font-size: large;
  font-weight: 600;
  width: 50%;
  display: flex;
  justify-content: space-around;
}

#technologies-list li {
  display: inline;
}

#problem-text {
  width: 55%;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .top-row {
    margin-left: 10%;
    margin-right: 10%;
  }

  .extra-content-row {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    text-align: left;
    flex-direction: column;
  }

  #technologies-list {
    width: 90%;
  }

  #problem-text {
    width: 100%;
  }

  #icon-container {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  #technologies-list {
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
    padding-inline-start: 0px;
  }

  #technologies-list li {
    margin-left: 5%;
  }
}
