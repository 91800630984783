nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  background-color: var(--dark-grey);
  width: 95%;
  height: 60px;
}

a {
  color: var(--lighter);
  text-decoration: none;
}

nav ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 300px;
}

.nav-list {
  margin-top: 3rem;
  font-size: 140%;
}

.nav-list li {
  padding-right: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.nav-list-item:hover {
  transition: ease-in-out 0.3s;
  color: var(--light-green);
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1200px;
}

.header-avatar {
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
  top: 500px;
}

.hero p {
  color: var(--font-header-secondary);
  width: 60%;
  text-align: center;
  font-size: 150%;
  font-weight: 600;
}

#name {
  color: var(--light-green);
  font-size: 400%;
  position: absolute;
  top: 300px;
}

@media screen and (max-width: 768px) {
  .nav-list li {
    padding-right: 0;
  }
  nav {
    padding: 0;
  }
  .hero {
    height: 600px;
  }
  #name {
    position: static;
  }
  .hero p {
    position: relative;
    bottom: 540px;
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .hero p {
    bottom: 600px;
  }
}
