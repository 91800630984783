.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
}

.skills-list {
  margin-top: 0;
  padding-left: 3rem;
  padding-right: 3rem;
}

.skills-list li::before {
  content: "\25A0";
  color: var(--dark-green);
  font-size: 0.5rem;
  padding-right: 0.5rem;
  position: relative;
  bottom: 0.2rem;
}

.skills-list li {
  text-align: left;
}

#about-me h1 {
  margin-bottom: 0;
  text-align: left;
  font-size: 3rem;
  padding-left: 3rem;
}

#about-me p {
  margin-top: 0;
  width: 50%;
  text-align: left;
  font-size: 1.5rem;
  padding-left: 3rem;
}

#about-me {
  display: flex;
  flex-direction: column;
  background-color: var(--light-green);
}

#skills-div {
  margin-top: 0;
  display: flex;
  flex-direction: row;
}

#skills-header {
  margin-top: 0;
  text-align: left;
  padding-left: 3rem;
}

#avatar {
  padding-right: 5rem;
}

@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
  }
  #about-me p {
    width: 100%;
    font-size: medium;
    padding-left: 1rem;
  }
  #avatar {
    padding-right: 0;
  }
}
